export const questions = [
  {
    id: 1,
    text: 'Como funciona a Nossa Vaquinha? ',
    description: 'A plataforma Nossa Vaquinha permite criar campanhas de arrecadação coletiva online através de doações. Os valores arrecadados são transferidos  em segurança para uma conta bancária em nome do titular com o mesmo CPF, ou CNPJ cadastrados inicialmente na criação da conta de beneficiário da plataforma Nossa Vaquinha.'
  },
  {
    id: 2,
    text: 'A plataforma Nossa Vaquinha é segura?',
    description: 'O site é totalmente seguro. Todas as doações são protegidas por tecnologia de segurança bancária e são transferidas diretamente para a conta-corrente do beneficiário da vaquinha, com o mesmo CPF ou CNPJ cadastrados inicialmente para criação de uma conta na plataforma Nossa Vaquinha. <br></br> Nossa plataforma é 100% segura porque utilizamos a Pagar.me, uma empresa do grupo STONE, a mesma tecnologia de segurança que realiza os pagamentos de grandes lojas virtuais, como por exemplo, o Magazine Luiza. <br></br> A STONE possui a certificação Payment Card Industry Data Security Standard (PCI-DSS), o que significa que todas as informações são criptografadas de ponta a ponta. <br><br>Além disso, a Pagar.me é homologada como gateway pela Cielo, o que só é concedido a empresas que seguem todas as normas de segurança do PCI.'
  },
  {
    id: 3,
    text: 'Existem custos para se criar uma vaquinha? ',
    description: 'Você usuário, beneficiário da campanha, não tem nenhum custo para personalizar o seu site com todos os recursos. <br/><br/> Para os beneficiários que NÃO são mães é descontada uma tarifa de R$1,00 (um real) por doação. Se não houver doação, não haverá nenhum custo. <br><br>Já para as mães e OSC (entidades beneficentes ou empresas do Terceiro Setor) não existem custos de criação de campanha e não há desconto sobre doações. '
  },
  {
    id: 4,
    text: 'Qual a tarifa de saque dos valores recebidos nas doações?',
    description: 'Para todos os usuários, incluindo mães e OSC, existe uma tarifa que se refere ao serviço de transferência bancária no valor R$ 3,67 por cada resgate (saque) realizado. O valor de R$ 3,67 NÃO é cobrado sobre cada doação recebida e sim por cada resgate dos valores disponíveis. <br><br> Dica: Orientamos que o beneficiário aguarde receber todas as doações para que se pague uma única vez o valor de R$ 3,67 por todo o valor arrecadado. ',
  },
  {
    id: 5,
    text: 'Por quanto tempo a campanha ficará no ar? ',
    description: 'O site de sua campanha ficará no ar por até 1 (um) ano (12 meses), sem nenhum custo adicional por isso. Ao final de 1 (um) ano, a campanha sairá do ar.'
  },
  {
    id: 6,
    text: 'Quais são as taxas de serviços para quem realiza doações?',
    description: 'Para os doadores há uma taxa de <strong>6,5%</strong> de acréscimo sobre o valor a ser doado. Este valor aparece em separado e fica visível ao doador como “taxa de serviços”, antes da confirmação do pagamento. <br></br> Entendemos que não faz sentido descontarmos do beneficiário da vaquinha as taxas de serviços, pois quem faz uma vaquinha precisa de toda a ajuda possível. <br></br> A Nossa Vaquinha inovou o mercado de vaquinhas ao cobrar taxa zero para beneficiários que são mães ou entidades beneficentes. <br></br> Ao cobrarmos a taxa de 6,5% do doador, este custo fica diluído em muitas pessoas que têm condições de ajudar. Exemplo, para uma doação de R$10,00, a taxa para o doador acresce apenas R$0,70 centavos na doação  totalizando R$10,70. Portanto, a mãe beneficiária receberá R$10,00 livre de taxas e impostos. <br></br> Agora vamos supor que uma campanha tenha arrecadado 1.000 doações de R$10,00 cada, totalizando 10 mil reais no final da campanha. Em todos os outros modelos de vaquinha que existem no mercado, a mãe beneficiária da vaquinha teria que pagar sozinha algo em torno de R$700,00 referente a taxas de operações, recebendo líquidos apenas R$9.300,00. Em nosso modelo, o beneficiário receberá R$10.000,00 líquidos, livre de taxas e impostos e pagará apenas R$3,67 (três reais e sessenta e sete centavos), que é a tarifa de saque. Isto porque a Nossa Vaquinha já recolheu em cada doação as tarifas e impostos obrigatórios diluídos em cada doador, com um mínimo de acréscimo.'
  },
  {
    id: 7,
    text: 'Para que se destinam as taxas de serviços? ',
    description: 'A taxa de serviços é essencial para o funcionamento da plataforma. São custos de servidor, tarifas bancárias, sistema de segurança, sistema antifraude, impostos e taxa administrativa, equipes de desenvolvimento, manutenção e suporte ao usuário da Nossa Vaquinha. '
  },
  {
    id: 8,
    text: 'Quanto paga de tarifas um beneficiário que não é mãe e não é OSC?',
    description: 'Não sendo uma mãe ou OSC  (entidades beneficentes, Terceiro Setor, ONG’s, etc.), o beneneficiário pagará R$ 1,00 (Um real) por doação, independentemente do valor valor doação. Pagará também uma tarifa de saque no valor de R$ 3,67, a qual sugerimos pagar uma única vez, desde que aguarde receber todas as doações para que se pague essa tarifa uma única vez.',
  },
  {
    id: 9,
    text: 'Por que solicitam o meu CPF para cadastro inicial?',
    description: 'O CPF é necessário para que a Pagar.me, empresa do Grupo Stone, responsável pela segurança e gestão financeira, possa garantir que a quantia em dinheiro das doações sejam transferidas para uma conta corrente cadastrada somente em seu CPF. Esta é uma norma de segurança automática da Pagar.me, para impedir que o resgate da quantia seja feito por terceiros. '
  },
  {
    id: 10,
    text: 'Como funciona a solicitação de resgate e quais são os prazos para liberação dos valores da doação recebida?',
    description: 'O resgate somente será permitido em seu PAINEL FINANCEIRO quando houver confirmação de pagamento do presente recebido e quando for cumprido o prazo para liberação. Os valores liberados para o resgate ficarão visíveis no campo: “Saldo Disponível para Resgate”.<br></br> Com relação aos prazos, segue abaixo: <br></br> Cartão de crédito: liberação entre 30 dias corridos (mínimo) e 33 dias corridos (máximo), a partir da data da compra do presente.<br></br>PIX: liberação em alguns minutos ou até 24h a partir do pagamento. <br><br> Boleto: liberação entre 2 dias úteis (mínimo) e 4 dias úteis (máximo), a partir da data do pagamento do boleto efetivado pelo comprador.  <br></br>Atenção: Em seu painel de INFORMAÇÕES FINANCEIRAS, você poderá consultar o seu Saldo disponível para resgate e os valores pendentes de liberação de cada presente foi comprado. Porém, a Nossa Vaquinha informa apenas quais doações foram concluídas e estão aguardando prazo de liberação. Após concluído o prazo para liberação das doações, aparecerá no painel o valor disponível para resgate. Então, o usuário deverá clicar em: RESGATAR.  '
  },
  {
    id: 11,
    text: 'Quais são os serviços administrativos prestados pela plataforma Nossa Vaquinha e qual o compromisso da empresa Pagar.me? ',
    description: 'É de responsabilidade da Nossa Vaquinha cuidar de custos administrativos, que são: impostos, equipe de tecnologia e desenvolvimento, equipe de criação artística e equipes de comunicação nas redes sociais e suporte ao usuário. A Pagar.me, empresa do grupo STONE, é responsável pela segurança, contabilidade e gestão financeira dos presentes recebidos até o momento da transferência para a conta corrente do usuário criador do evento.  ',
  },
  {
    id: 12,
    text: 'Eu já recebi doações, porém  gostaria de alterar o nome e CPF para receber os valores em conta corrente de outra pessoa. Isso é possível?',
    description: 'Em virtude da segurança exigida pela operadora Pagar.me, não é possível alterar o CPF, ou seja, não é possível alterar o titular da conta que irá receber os valores de doações já pagas por seus doadores. Esta  norma de segurança é importante para impedir que seu resgate seja feito por terceiros. <br></br> Caso você ainda não tenha compartilhado o site de sua campanha com seus convidados, e somente neste caso, você queira cadastrar outro CPF, deve-se começar tudo do zero. Ou seja: criar uma nova conta, com um novo CPF, novo email e senha na Nossa Vaquinha e criar uma nova campanha. Para demais questões relacionadas a este tema, favor contatar a equipe de suporte. Teremos prazer em te ajudar: contato@nossavaquinha.com.br'
  },
  {
    id: 13,
    text: 'É possível alterar os dados bancários para recebimento do valor/dinheiro da campanha?',
    description: 'Sim, porém o novo banco a ser cadastrado deverá necessariamente estar em nome do CPF ou CNPJ, previamente cadastrado na Nossa Vaquinha. Não é possível alterar o CPF ou CNPJ cadastrado em virtude da segurança exigida pela operadora PAGAR.ME. Esta norma de segurança é para impedir que seu resgate seja feito por terceiros.'
  },
  {
    id: 14,
    text: 'Como é realizada a transferência bancária dos valores recebidos?',
    description: 'O dono do evento irá visualizar em seu Painel Financeiro o valor disponível para resgate e informará os dados de agência e conta para transferência. Importante: a transferência bancária ocorrerá apenas para uma conta bancária do mesmo CPF informado no momento do cadastro inicial do perfil de usuário no site Nossa Vaquinha.'
  },
  {
    id: 15,
    text: 'Eu posso criar mais de uma campanha com a minha mesma conta? ',
    description: 'Sim. Para criar um novo evento, basta selecionar a opção “Criar novo evento” em seu painel de controle (home do usuário) após realizar o login.'
  },
  {
    id: 16,
    text: 'Até quando é possível doações em uma campanha?',
    description: 'Enquanto a página estiver no ar, ou seja, no período de 1 (um) ano, será possível receber doações. Importante lembrar que o beneficiário poderá sacar valores de um evento em específico em até 1 (um) ano após a data de liberação do valor de um presente em específico e desde que o perfil esteja ativo em nosso banco de dados.'
  },
  {
    id: 17,
    text: 'Qual o prazo para solicitar transferência de valores dos presentes recebidos e liberados para saque? ',
    description: 'Após a confirmação de pagamento e o prazo de liberação do valor para saque de um presente recebido, o prazo para realizar o resgate deste presente convertido em dinheiro é de 365 dias corridos, desde que o usuário não tenha solicitado exclusão de sua conta no site da Nossa Vaquinha.'
  },
  {
    id: 18,
    text: 'É possível alterar um e-mail cadastrado em minha conta? ',
    description: 'Entre em contato conosco através do e-mail contato@nossavaquinha.com.br e solicite a troca do email com a respectiva justificativa para análise de segurança do time de suporte.'
  },
  {
    id: 19,
    text: 'O site Nossa Vaquinha funciona para quem mora fora do Brasil? ',
    description: 'Para quem realiza o evento será necessário informar um CPF brasileiro e uma conta corrente em um banco brasileiro com o mesmo CPF para poder receber eventuais valores de doaçoes. Para quem irá fazer uma doação, e mora fora do Brasil, basta ter um cartão de crédito internacional ou pagar no pix através de uma conta bancária nacional.'
  },
  {
    id: 20,
    text: 'Como excluir uma campanha na Nossa Vaquinha?',
    description: 'Para excluir uma campanha basta entrar no site com seu login e senha. Estamos na home de suas campanhas, passe o mouse sobre a campanha que deseja excluir e clique sobre o ícone da lixeira que aparecerá no canto superior direito da imagem que representa sua campanha. Se tiver dificuldade basta nos solicitar ajuda no e-mail contato@nossavaquinha.com.br '
  },
  {
    id: 21,
    text: 'Como excluir minha conta em definitivo da plataforma Nossa Vaquinha?',
    description: 'Para excluir sua conta definitivamente, solicite através do e-mail contato@nossavaquinha.com.br. Importante considerar que, uma vez solicitada a exclusão da conta, serão excluídas todas as suas campanhas e todo o acesso à sua página com os informes de doações recebidas. Importante checar antes de dar início a este processo se há algum resgate financeiro autorizado, ou aguardando autorização, referente a doações recebidas.  '
  },
  {
    id: 22,
    text: 'O valor das doações recebidas são transferidas automaticamente para minha conta corrente?',
    description: 'Não. Para realizar o resgate, o beneficiário da vaquinha deverá estar logado no site Nossa Vaquinha, acessar o Painel Financeiro e clicar no botão “Resgatar”, se houver valor disponível para resgate (saque). <br></br> Lembrando que para realizar o resgate, o usuário deverá ter informado previamente os dados bancários em nome da pessoa com o mesmo CPF cadastrado inicialmente na Nossa Vaquinha.'
  },
  {
    id: 23,
    text: 'Existe taxa para emissão do boleto bancário? ',
    description: 'Sim, esta taxa é cobrada no valor de R$ 3,49 pelo banco emissor do boleto e não recai sobre o beneficiário da vaquinha, ou seja, quem paga é o doador que solicita o boleto como forma de pagamento. Recomendamos o uso do PIX para que não recaia taxa para o convidado.'
  },
  {
    id: 24,
    text: 'Houve uma falha na minha solicitação de resgate, o que fazer?',
    description: 'Primeiro verifique se existe saldo disponível para resgate.<br></br> Depois, verifique se você configurou corretamente todas as informações do cadastro de sua conta corrente ou conta poupança. Você acessa a configuração de cadastro no botão amarelo que fica ao lado do botão de Resgate (verde) em seu painel financeiro. <br></br> Uma vez na tela de configurações de cadastro de sua conta corrente, confira se todos os dados estão preenchidos corretamente, tais como: nome completo, nome do banco, número da agência (sem o dígito) e número da conta, com o dígito da conta em campo separado. Por fim, verifique se todos os campos do seu endereço estão preenchidos corretamente para que você possa receber a nota fiscal dos serviços a Nossa Vaquinha. <br></br> O CPF, ou CNPJ, do titular desta conta bancária deve ser o mesmo que se cadastrou no site da Nossa Vaquinha. Esta é uma norma de segurança automática da Pagar.me, para impedir que seu resgate seja feito por terceiros. <br></br> Caso tudo isso não resolva, pode ser que você tenha ficado um tempo sem conexão e o seu login tenha caído. Basta, então, fechar o site do Nossa Vaquinha, para depois reabri-lo e fazer o login novamente. Repita a operação de resgate em seu Painel de Financeiro. Se nada disso resolver, entre em contato com nossa equipe de suporte através do contato@nossavaquinha.com.br Será um prazer te ajudar.'
  },
  {
    id: 25,
    text: 'Após solicitar o resgate, em quanto tempo o valor cairá na minha conta bancária?',
    description: 'O valor estará em sua conta bancária em alguns minutos, a depender do horário da solicitação, ou em até 24h de um dia útil. '
  },
  {
    id: 26,
    text: 'Como saber se um beneficiário recebeu minha doação? ',
    description: 'Transparência é um princípio que não abrimos mão. Tanto o beneficiário, como também o doador, receberá um e-mail informando quando o pagamento da doação for concluída, e o respectivo valor doado. Além disso,  o valor da, quem a fez, o dia e horário, aparecerão automaticamente, tanto no painel financeiro do beneficiário, como no site público da campanha, com o nome e a primeira letra do sobrenome de quem realizou a doação. Caso o doador escolha aparecer de forma anônima, seu nome não aparecerá, mas aparecerá o valor e o horário da conclusão da doação.'
  },
  {
    id: 27,
    text: 'O meu resgate falhou e fui cobrado pela taxa de saque mesmo assim, o que aconteceu?',
    description: 'A Pagar.me cobra uma taxa de R$ 3,67 pelo serviço de resgate, mesmo que o resgate venha a falhar por erro no cadastro feito pelo usuário. A Pagar.me justifica essa cobrança por haver necessidade de análise por sua equipe para verificar o erro do usuário nos dados bancários. <br></br> Por isso, recomendamos o máximo de cuidado ao preencher todos os campos do cadastro bancário, antes de solicitar o seu resgate. Para orientações clique em Leia-me que fica no formulário de cadastro dos dados bancários, o qual você acessa no botão amarelo dentro do seu Painel Financeiro. <br></br> Se surgir alguma dúvida na hora de preencher o cadastro bancário, entre em contato com o nosso suporte: contato@nossavaquinha.com.br.'
  },
];